import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { isset } from '@cawita/core-front';
import { isEmptyOrNotSet } from '@hints/utils/data';
import { isString } from '@hints/utils/types';
import { User } from '@shared/models';
import { TesterAuthAPI } from '@shared/services/tester-auth.api';
import { map, Observable, of, switchMap, timer } from 'rxjs';
import { provideAsyncValidator } from '../simple-value-accessor.directive';

@Directive({
  selector: '[cwtUniqueEmail]',
  standalone: true,
  providers: [provideAsyncValidator(forwardRef(() => UniqueEmailDirective))]
})
export class UniqueEmailDirective implements AsyncValidator {
  constructor(private auth: TesterAuthAPI) { }
  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const email = isString(control.value) ? control.value : '';
    if(isEmptyOrNotSet(email)) return of(null);
    return timer(500).pipe(
      switchMap(() => this.auth.checkEmail(email)),
      map(available => !available ? { uniqueEmail: true } : null)
    );
  }
}
