import { Injectable } from '@angular/core';
import { CwtI18nService } from '@cawita/core-front';
import { CwtApiService, CwtModelConstructor, CwtModelType, CwtRequestConfig } from '@cawita/core-front/api';
import { Machine, Tester, TesterGender } from '@shared/models';
import { MonoTypeOperatorFunction, Observable, map, of } from 'rxjs';

export type MachineCredentials = {
    uniqueId: string;
    password: string;
    macAddr: string;
}

export type TesterCredentials = {
    email: string;
    password: string;
}

export type TesterRegister = {
    login: string;
    email: string;
    password: string;
}

export type TesterResetPassword = {
    email: string;
    password: string;
    code: string;
}

export type TesterForgotPassword = {
    email: string;
}

export type MachineAuthResponse = { token: string; machine: Machine; }
export type TesterAuthResponse = { token: string; tester: Tester; }

@Injectable({ providedIn: 'root' })
export class TesterAuthAPI {
    constructor(
        private i18n: CwtI18nService,
        private api: CwtApiService
    ) { }

    public getLoggedMachine(config?: CwtRequestConfig) { return this.api.get(`for-machine/machine/logged`, {}, config).pipe(map(r => new Machine(r))); }
    public getLoggedTester(config?: CwtRequestConfig) { return this.api.get(`for-machine/tester/mine`, {}, config).pipe(map(r => new Tester(r))); }

    /**
     * Authenticates to a machine (use on manual authentication)
     * @param payload machine auth credentials
     * @param config 
     * @returns
     */
    public machineManualLogin(payload: MachineCredentials, config?: CwtRequestConfig) {
        return this.api.post(`for-machine/machine/first-logged`, payload, config).pipe(mapMachineAuth());
    }

    /**
     * Authenticates to a machine (use on refresh authentication)
     * @param payload machine auth credentials
     * @param config 
     * @returns
     */
    public machineRefreshLogin(payload: MachineCredentials, config?: CwtRequestConfig) {
        return this.api.post(`for-machine/machine/logged`, payload, config).pipe(mapMachineAuth());
    }

    /**
     * Authenticates an already registered tester (require machine token)
     * @param payload 
     * @param config 
     * @returns 
     */
    public testerLogin(payload: TesterCredentials, config?: CwtRequestConfig) {
        return this.api.post(`for-machine/tester/logged`, payload, config).pipe(mapTesterAuth());
    }

    /**
     * Register and authenticates a new tester (require machine token)
     * @param payload 
     * @param config 
     * @returns 
     */
    public testerRegister(payload: TesterCredentials, config?: CwtRequestConfig) {
        return this.api.post(`for-machine/tester`, payload, config).pipe(mapTesterAuth());
    }

    public testerForgotPassword(payload: TesterForgotPassword, config?: CwtRequestConfig) {
        return this.api.post<any>(`for-machine/tester/forgot-password`, {
            locale: this.i18n.currentLocale,
            ...payload,
        }, config).pipe(map(s => s.success));
    }

    public testerResetPassword(payload: TesterResetPassword, config?: CwtRequestConfig): Observable<boolean> {
        return this.api.post<any>(`for-machine/tester/reset-password`, payload, config).pipe(map(s => s.success));
    }

    public deleteAccount() {
        return this.api.delete<any>('for-machine/tester/mine');
    }

    public checkEmail(email: string): Observable<boolean> {
        return this.api.post<{ available: boolean }>(`/for-machine/tester/check-email`, { email }).pipe(map(r => r?.available));
    }

    public checkLogin(login: string): Observable<boolean> {
        return this.api.post<{ available: boolean }>(`/for-machine/tester/check-login`, {login }).pipe(map(r => r?.available));
    }
}

function mapMachineAuth(): MonoTypeOperatorFunction<MachineAuthResponse> { return source => source.pipe(map(res => ({ token: res.token, machine: new Machine(res.machine) }))); }
function mapTesterAuth(): MonoTypeOperatorFunction<TesterAuthResponse> { return source => source.pipe(map(res => ({ token: res.token, tester: new Tester(res.tester) }))); }