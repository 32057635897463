'use-strict';
const { isset } = require('@hints/utils/data');

module.exports = class Protocol {
    /**
     * @param {any} evaluation
     * @param {'ms' | 'mr'} gender
     * @param {number} age 
     * @param {any} data
     * @returns {{ score: number, data: any }} data
     */
    getScore(evaluation, gender, age, data) {
        return {
            score: 0,
            data: {}
        };
    }

    validateResults(body) {
        if(!isset(body)) return false;
        if(!isset(body.score)) return false;
        if(body.score < 0 || body.score > 100) return false;
        return true;
    }
};