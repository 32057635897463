import { ChangeDetectorRef, inject } from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";
import { asArray, isset } from "@cawita/core-front";
import { CUSTOM_FIELD } from "@shared/modules/custom-fields";

export class CustomFieldInput<T> implements ControlValueAccessor {
    private _cdRef = inject(ChangeDetectorRef);

    private _value: T | T[];
    private _onChange = (value: T | T[]) => { };
    private _onTouched = () => { };

    protected readonly customField = inject(CUSTOM_FIELD);
    protected disabled = false;

    public get value(): T | T[] { return this._value; }
    public set value(v: T | T[]) { this.setValue(v); }

    protected formatValue(v: any | null): T { return v as T; }

    writeValue(obj: any | any[] | null | undefined): void {
        this._value = this.coerceValue(obj);
        this._cdRef.markForCheck();
    }

    registerOnChange(fn: any): void { this._onChange = fn; }
    registerOnTouched(fn: any): void { this._onTouched = fn; }
    setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled; }

    protected setValue(value: T | T[]) {
        this._value = this.coerceValue(value);
        this._onChange(this._value);
        this._onTouched();
        this._cdRef.markForCheck();
    }

    protected coerceValue(value: any | any[] | null | undefined): T | T[] {
        if (this.customField.isMultiSelect) {
            if (!isset(value)) return [];
            return asArray(value).filter(isset).map((v) => this.formatValue(v));
        } else {
            if (!isset(value)) return null;
            const newValue = Array.isArray(value) ? value[0] : value;
            if (!isset(newValue)) return null;
            return this.formatValue(newValue);
        }
    }
}