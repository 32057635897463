'use-strict';

const Protocol = require('./protocol');
const pop = require('./population-stats');
const config = require('../data/mental-config.json');
const { atan2Deg, average, normalize0100 } = require('../utils/math');
const gaze = require('../utils/gaze');
const { round } = require('@hints/utils/math');
const { isset, asArray, isEmptyOrNotSet, findByAddress } = require('@hints/utils/data');
const { isNumber } = require('@hints/utils/types');

/**
 * @typedef {Object} MentalRecordPOG
 * @property {number} x
 * @property {number} y
 * @property {boolean} valid
 */
/**
 * @typedef {Object} MentalRecordEye
 * @property {number} x
 * @property {number} y
 * @property {number} z
 * @property {number} d
 * @property {boolean} valid
 */

/**
 * @typedef {Object} MentalRecord
 * @property {number} count
 * @property {number} time
 * @property {{
 *  left: MentalRecordPOG,
 *  right: MentalRecordPOG,
 *  best: MentalRecordPOG,
 * }} pog
* @property {{
*  left: MentalRecordEye,
*  right: MentalRecordEye,
* }} eye
 */

class MentalProtocol extends Protocol {
    constructor() {
        super();
        this.testSet = asArray(config.guidedSaccade).map(_formatTestItem);
        this.pxToDeg = round(atan2Deg(Math.round(config.screenSizeMm[1] / 2), config.viewingDistanceMm) / (Math.round(config.screenSizePx[1] / 2)), 4);
        this.degToPx = round(1 / this.pxToDeg, 4);
        this.areaSizeDeg = config.areaSizeDeg;
        this.fixationDisplayTimeMs = config.fixationDisplayTimeMs;
        this.targetDisplayTimeMs = config.targetDisplayTimeMs;
        this.blankTimeMs = config.blankTimeMs;
    }

    /**
     * 
     * @param {*} evaluation 
     * @param {'mr' | 'ms'} gender 
     * @param {number} age 
     * @param {{
     *  fixationRecords: MentalRecord[],
     *  saccadeRecords: MentalRecord[]
     * }} data 
     */
    getScore(_evaluation, gender, age, data) {
        const { fixationRecords, saccadeRecords } = data;
        const { mean, sd } = pop.mentalAvgSaccAmp.getData(gender, age);
        const { vmax = 0, vmean = 0 } = this.getSaccadeResults(saccadeRecords);
        const score = normalize0100((vmean - mean) / sd, config.zMin, config.zMax);
        const { leftEye, rightEye } = _getMeanEyeDiameters(fixationRecords);
        return {
            logs: data,
            data: {
                score,
                result: {
                    vmax,
                    vmean,
                    leftEye,
                    rightEye
                }
            }
        };
    }
    
    validateResults(body) {
        if(!super.validateResults(body)) return false;
        const { result } = body;
        if(!isset(result)) return false;
        if(isset(result.pupil)) {
            const { left, right, best } = result.pupil;
            if(!isset(left) || !isNumber(left)) return false;
            if(!isset(right) || !isNumber(right)) return false;
            if(!isset(best) || !isNumber(best)) return false;
        }
        return true;
    }

    getSaccadeResults(saccadeRecords) {
        const validRecords = saccadeRecords.filter(p => isset(p.pog) && isset(p.pog.best) && p.pog.best.valid);
        const positions = validRecords.map(d => this.getRecordAngle(d));
        const velocities = gaze.vecvel(positions, config.frequencyHz);
        const { vmax = 0, vmean = 0 } = this.computePeaks(positions, velocities);
        return { vmax, vmean };
    }

    getFixationResults(fixationRecords) {
        return _getMeanEyeDiameters(fixationRecords);
    }

    /**
     * 
     * @param {MentalRecord} data 
     */
    getRecordAngle(data) {
        const { x, y } = data.pog.best;
        const xAngle = x * config.screenSizePx[0] * this.pxToDeg;
        const yAngle = y * config.screenSizePx[1] * this.pxToDeg;
        return [xAngle, yAngle];
    } 

    /**
     * 
     * @param {[number, number][]} positions 
     * @param {[number, number][]} velocities 
     */
    computePeaks(positions, velocities) {
        const { saccades } = gaze.microsacc(positions, velocities, config.relativeVelocityThreshold, config.minSaccadeDuration);
        const minSaccadeInterval = 0.02 / (1 / config.frequencyHz);
        const validSaccades =  saccades.filter(s => ((s.end - s.onset) >= minSaccadeInterval && s.vpeak <= config.maxSaccadicVelocityDeg));
        let vmean = average(validSaccades.filter(s => s.ah > 15 && s.ah <= 20).map(s => s.vpeak)) || 0;
        let vmax = Math.max(...validSaccades.map(p => p.vpeak));
        if (!isset(vmean)) vmean = 0;
        if (!isset(vmax)) vmax = 0;
        return { vmean, vmax };
    }

    sanitizePupilValues(pupil) {
        if(!isset(pupil)) return undefined;
        const { left, right, best } = pupil;
        return {
            left: isset(left) ? left : 0,
            right: isset(left) ? right : 0,
            best: isset(left) ? best : 0,
        };
    }
}

module.exports = new MentalProtocol();

/**
 * 
 * @param {[keyof typeof config.positions, string, number]} item 
 * @returns {
 *  fixation: [number, number],
 *  offset: [number, number],
 * }
 */
function _formatTestItem(item) {
    const [position, direction, distance] = item;
    const locationConfig = config.positions[position];
    const directionConfig = locationConfig.offsets[direction] || [0, 0];
    const offsetLength = Math.hypot(directionConfig[0], directionConfig[1]);
    const offsetNormalized = [(directionConfig[0] / offsetLength), (directionConfig[1] / offsetLength)];
    return {
        fixation: locationConfig.position,
        offset: [offsetNormalized[0] * distance, offsetNormalized[1] * distance],
    };
}

/**
 * @param {MentalRecord[]} fixationRecords
 */
function _getMeanEyeDiameters(fixationRecords) {
    if(isEmptyOrNotSet(fixationRecords)) return { left: 0, right: 0 };
    const statDef = () => ({ count: 0, total: 0});
    const statMean = (stat) => (stat.count ? (stat.total / stat.count ) : 0) * 1000;
    const addRecord = (stat, eye) => {
        if(!eye || !eye.valid) return;
        stat.count++;
        stat.total += (eye.d || 0);
    };

    const complete = { left: statDef(), right: statDef() };
    const end = { left: statDef(), right: statDef() };
    const middle = { left: statDef(), right: statDef() };
    
    const startTime = fixationRecords[0].time;
    const endTime = fixationRecords[fixationRecords.length - 1].time;

    fixationRecords.forEach(record => {
        const eyeLeft = findByAddress(record, 'eye.left');
        const eyeRight = findByAddress(record, 'eye.right');
        addRecord(complete.left, eyeLeft);
        addRecord(complete.right, eyeRight);
        const offsetToStart = record.time - startTime;
        const offsetToEnd = endTime - record.time;
        if(offsetToStart >= 5) {
            addRecord(end.left, eyeLeft);
            addRecord(end.right, eyeRight);
            if(offsetToEnd >= 5) {
                addRecord(middle.left, eyeLeft);
                addRecord(middle.right, eyeRight);    
            }
        }
    });

    return {
        leftEye: {
            complete: statMean(complete.left),
            middle: statMean(middle.left),
            end: statMean(end.left),
        },
        rightEye: {
            complete: statMean(complete.right),
            middle: statMean(middle.right),
            end: statMean(end.right),
        }
    };
}