import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Address } from "../common";
import { Client } from "./client.model";

@CwtModel({ name: 'site' })
@CwtCrudContract({ path: 'site', searchMapper: (s) => ({ name: { regexp: s } }) })
@CwtCrudContract({ path: 'for-manager/client/:clientId/site', identifier: 'for-manager', searchMapper: (s) => ({ name: { regexp: s } }), })
export class Site extends CwtApiEntity {
    @CwtProp({ ref: Client }) client: Client;
    @CwtProp() name: string;
    @CwtProp() phone: string;
    @CwtProp() email: string;
    @CwtProp({ ref: Address }) address: Address;
}