'use-strict';

const QuestionProtocol = require('./question-protocol');

module.exports = new QuestionProtocol((_evaluation, _gender, _age, answerScore) => {
    if (answerScore >= 25) return 73 + ((answerScore - 25) * 5.4);
    if (answerScore >= 15) return 31 + ((answerScore - 15) * 4.3);
    return answerScore * 2.1;
}, [
    {
        order: 1,
        text: { mr: 'protocol.v1_sleep.mr_q1', ms: 'protocol.v1_sleep.ms_q1' },
        answers: [
            { id: 'A', value: 5, text: { mr: 'protocol.v1_sleep.mr_q1_a1', ms: 'protocol.v1_sleep.ms_q1_a1' } },
            { id: 'B', value: 4, text: { mr: 'protocol.v1_sleep.mr_q1_a2', ms: 'protocol.v1_sleep.ms_q1_a2' } },
            { id: 'C', value: 3, text: { mr: 'protocol.v1_sleep.mr_q1_a3', ms: 'protocol.v1_sleep.ms_q1_a3' } },
            { id: 'D', value: 2, text: { mr: 'protocol.v1_sleep.mr_q1_a4', ms: 'protocol.v1_sleep.ms_q1_a4' } },
            { id: 'E', value: 1, text: { mr: 'protocol.v1_sleep.mr_q1_a5', ms: 'protocol.v1_sleep.ms_q1_a5' } }
        ]
    },
    {
        order: 2,
        text: { mr: 'protocol.v1_sleep.mr_q2', ms: 'protocol.v1_sleep.ms_q2' },
        answers: [
            { id: 'A', value: 5, text: { mr: 'protocol.v1_sleep.mr_q2_a1', ms: 'protocol.v1_sleep.ms_q2_a1' } },
            { id: 'B', value: 4, text: { mr: 'protocol.v1_sleep.mr_q2_a2', ms: 'protocol.v1_sleep.ms_q2_a2' } },
            { id: 'C', value: 3, text: { mr: 'protocol.v1_sleep.mr_q2_a3', ms: 'protocol.v1_sleep.ms_q2_a3' } },
            { id: 'D', value: 2, text: { mr: 'protocol.v1_sleep.mr_q2_a4', ms: 'protocol.v1_sleep.ms_q2_a4' } },
            { id: 'E', value: 1, text: { mr: 'protocol.v1_sleep.mr_q2_a5', ms: 'protocol.v1_sleep.ms_q2_a5' } }
        ]
    },
    {
        order: 3,
        text: { mr: 'protocol.v1_sleep.mr_q3', ms: 'protocol.v1_sleep.ms_q3' },
        answers: [
            { id: 'A', value: 5, text: { mr: 'protocol.v1_sleep.mr_q3_a1', ms: 'protocol.v1_sleep.ms_q3_a1' } },
            { id: 'B', value: 4, text: { mr: 'protocol.v1_sleep.mr_q3_a2', ms: 'protocol.v1_sleep.ms_q3_a2' } },
            { id: 'C', value: 3, text: { mr: 'protocol.v1_sleep.mr_q3_a3', ms: 'protocol.v1_sleep.ms_q3_a3' } },
            { id: 'D', value: 2, text: { mr: 'protocol.v1_sleep.mr_q3_a4', ms: 'protocol.v1_sleep.ms_q3_a4' } },
            { id: 'E', value: 1, text: { mr: 'protocol.v1_sleep.mr_q3_a5', ms: 'protocol.v1_sleep.ms_q3_a5' } }
        ]
    },
    {
        order: 4,
        text: { mr: 'protocol.v1_sleep.mr_q4', ms: 'protocol.v1_sleep.ms_q4' },
        answers: [
            { id: 'A', value: 5, text: { mr: 'protocol.v1_sleep.mr_q4_a1', ms: 'protocol.v1_sleep.ms_q4_a1' } },
            { id: 'B', value: 4, text: { mr: 'protocol.v1_sleep.mr_q4_a2', ms: 'protocol.v1_sleep.ms_q4_a2' } },
            { id: 'C', value: 3, text: { mr: 'protocol.v1_sleep.mr_q4_a3', ms: 'protocol.v1_sleep.ms_q4_a3' } },
            { id: 'D', value: 2, text: { mr: 'protocol.v1_sleep.mr_q4_a4', ms: 'protocol.v1_sleep.ms_q4_a4' } },
            { id: 'E', value: 1, text: { mr: 'protocol.v1_sleep.mr_q4_a5', ms: 'protocol.v1_sleep.ms_q4_a5' } }
        ]
    },
    {
        order: 5,
        text: { mr: 'protocol.v1_sleep.mr_q5', ms: 'protocol.v1_sleep.ms_q5' },
        answers: [
            { id: 'A', value: 5, text: { mr: 'protocol.v1_sleep.mr_q5_a1', ms: 'protocol.v1_sleep.ms_q5_a1' } },
            { id: 'B', value: 4, text: { mr: 'protocol.v1_sleep.mr_q5_a2', ms: 'protocol.v1_sleep.ms_q5_a2' } },
            { id: 'C', value: 3, text: { mr: 'protocol.v1_sleep.mr_q5_a3', ms: 'protocol.v1_sleep.ms_q5_a3' } },
            { id: 'D', value: 2, text: { mr: 'protocol.v1_sleep.mr_q5_a4', ms: 'protocol.v1_sleep.ms_q5_a4' } },
            { id: 'E', value: 1, text: { mr: 'protocol.v1_sleep.mr_q5_a5', ms: 'protocol.v1_sleep.ms_q5_a5' } }
        ]
    },
    {
        order: 6,
        text: { mr: 'protocol.v1_sleep.mr_q6', ms: 'protocol.v1_sleep.ms_q6' },
        answers: [
            { id: 'A', value: 5, text: { mr: 'protocol.v1_sleep.mr_q6_a1', ms: 'protocol.v1_sleep.ms_q6_a1' } },
            { id: 'B', value: 4, text: { mr: 'protocol.v1_sleep.mr_q6_a2', ms: 'protocol.v1_sleep.ms_q6_a2' } },
            { id: 'C', value: 3, text: { mr: 'protocol.v1_sleep.mr_q6_a3', ms: 'protocol.v1_sleep.ms_q6_a3' } },
            { id: 'D', value: 2, text: { mr: 'protocol.v1_sleep.mr_q6_a4', ms: 'protocol.v1_sleep.ms_q6_a4' } },
            { id: 'E', value: 1, text: { mr: 'protocol.v1_sleep.mr_q6_a5', ms: 'protocol.v1_sleep.ms_q6_a5' } }
        ]
    }
]);