import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { CustomFieldTarget } from "./custom-field-target.model";
import { CustomFieldType } from "./custom-field-type.model";
import { Client } from "../client";
import { CwtDictionary } from "@cawita/core-front";

@CwtModel({ name: 'custom-field' })
@CwtCrudContract({ path: 'for-machine/custom-field', identifier: 'for-machine' })
@CwtCrudContract({ path: 'for-manager/client/:clientId/custom-field', identifier: 'for-manager' })
@CwtCrudContract({ path: 'custom-field', identifier: 'for-admin' })
export class CustomField extends CwtApiEntity {
    @CwtProp() client: Client;
    @CwtProp() target: CustomFieldTarget;
    @CwtProp() type: CustomFieldType;
    @CwtProp() name: CwtDictionary;
    @CwtProp() enumValues: { key: string, label: CwtDictionary }[];
    @CwtProp() isMultiSelect: boolean;
    @CwtProp() isActive: boolean;
    @CwtProp() isVisibleInTable: boolean;
    @CwtProp() isVisibleInColumn: boolean;
    @CwtProp() order: number;
}