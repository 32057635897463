import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SharedCommonModule } from '@shared/modules';
import { CUSTOM_FIELD, CUSTOM_FIELD_VALUES } from '@shared/modules/custom-fields';

@Component({
  selector: 'cwt-custom-field-simple-cell',
  standalone: true,
  imports: [SharedCommonModule],
  template: `<span *ngFor="let v of customFieldValues; let l = last">{{v}}{{!l ? ', ' : ''}}</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFieldSimpleCellComponent {
  protected readonly customField = inject(CUSTOM_FIELD);
  protected readonly customFieldValues = inject(CUSTOM_FIELD_VALUES);
}
