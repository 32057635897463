import { InjectionToken, Type } from "@angular/core";
import { CustomField, CustomFieldType, CustomFieldValueTypes } from "@shared/models";

export const CUSTOM_FIELDS_CONFIG = new InjectionToken<ICustomFieldsConfig>('cwt-custom-fields-config');

export type ICustomFieldsConfig = {
    adapters: {
        [T in CustomFieldType]: {
            input: Type<any>,
            cell?: Type<any>
        };
    };
}

export const CUSTOM_FIELD = new InjectionToken<CustomField>('cwt-custom-field');
export const CUSTOM_FIELD_VALUES = new InjectionToken<CustomFieldValueTypes[]>('cwt-custom-field-value');