<nz-form-item>
    <nz-form-label>{{customField.name | cwtDictionary}}</nz-form-label>
    <nz-form-control>
        <nz-select 
            [disabled]="disabled"
            [(ngModel)]="value" 
            [nzMode]="customField.isMultiSelect ? 'tags' : 'default'">
            <nz-option *ngFor="let enumValue of customField.enumValues" [nzValue]="enumValue.key" [nzLabel]="enumValue.label | cwtDictionary"></nz-option>
        </nz-select>
    </nz-form-control>
</nz-form-item>