'use-strict';

const { asArray, isset, findByAddress } = require('@hints/utils/data');
const Protocol = require('./protocol');
const hrv = require('../utils/hrv');
const { normalize0100, average, weightedAverage } = require('../utils/math');
const pop = require('./population-stats');
const config = require('../data/energetical-config.json');

class EnergeticalProtocol extends Protocol {
    getScore(evaluation, gender, age, ppi) {
        const heartrate = this.computeMeanHR(ppi);
        const { rmssd, rmssdScore } = this.computeRmssdScore(gender, age, ppi);
        const { wellnessScore, physicalActivityScore, sportPracticeScore } = this.extractPhysicalScores(evaluation);
        const score = weightedAverage(
            [wellnessScore, physicalActivityScore, sportPracticeScore, rmssdScore],
            [config.evaCoeff, config.physicalActivityCoeff, config.sportPracticeCoeff, config.hrvCoeff]
        );

        return {
            logs: ppi,
            data: {
                score: score,
                wellnessScore,
                physicalActivityScore,
                sportPracticeScore,
                rmssdScore,
                result: {
                    rmssd,
                    heartrate,
                }
            }
        };
    }

    validateResults(body) {
        if (!super.validateResults(body)) return false;
        const { result } = body;
        if (!isset(result)) return false;
        if (!isset(result.rmssd)) return false;
        if (!isset(result.heartrate)) return false;
        return true;
    }

    computeMeanHR(ppi) {
        const validHR = ppi.map(ppi => ppi.ppiHr).filter(hr => hr > 0);
        return average(validHR);
    }

    computeRmssdScore(gender, age, ppi) {
        const { mean, sd } = pop.energeticalRMSSD.getData(gender, age);
        const rrIntervals = asArray(ppi).map(ppi => ppi.ppi / 1000);
        const rrIntervalsFiltered = hrv.rrFilter(rrIntervals);
        const rmssd = (hrv.rmssd(rrIntervalsFiltered) || 0.001) * 1000;
        const lnRMSSD = Math.log(rmssd);
        const score = normalize0100((lnRMSSD - mean) / sd, -3, 3);
        return { rmssdScore: score, rmssd };
    }

    extractPhysicalScores(evaluation) {
        /** @type {physical.PhysicalScoreParams} */
        const data = findByAddress(evaluation, 'evalPhysical');
        if(!isset(data)) return { wellnessScore: 0, physicalActivityScore: 0, sportPracticeScore: 0 };
        return {
            wellnessScore: findByAddress(data, 'wellnessScore') || 0,
            physicalActivityScore: findByAddress(data, 'physicalActivityScore') || 0,
            sportPracticeScore: findByAddress(data, 'sportPracticeScore') || 0,
        };
    }
}

module.exports = new EnergeticalProtocol();