import { ICustomFieldsConfig } from "@shared/modules/custom-fields";
import { CustomFieldSimpleCellComponent } from "@shared/widgets/custom-field/custom-field-cells/custom-field-simple-cell/custom-field-simple-cell.component";
import { CustomFieldEnumInputComponent } from "@shared/widgets/custom-field/custom-fields-inputs/custom-field-enum-input/custom-field-enum-input.component";
import { CustomFieldSimpleInputComponent } from "@shared/widgets/custom-field/custom-fields-inputs/custom-field-simple-input/custom-field-simple-input.component";

export const CwtCustomFieldsConfig: ICustomFieldsConfig = {
    adapters: {
        number: {
            input: CustomFieldSimpleInputComponent,
            cell: CustomFieldSimpleCellComponent,
        },
        string: {
            input: CustomFieldSimpleInputComponent,
            cell: CustomFieldSimpleCellComponent,
        },
        enum: {
            input: CustomFieldEnumInputComponent,
            cell: CustomFieldSimpleCellComponent,
        }
    }
}