import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Address } from "../common";

@CwtModel({ name: 'client' })
@CwtCrudContract({  path: 'client', searchMapper: (s) => ({ name: { regexp: s } }), })
export class Client extends CwtApiEntity {
    @CwtProp() name: string;
    @CwtProp({ ref: Address }) address: Address;
    @CwtProp() email: string;
    @CwtProp() phone: string;
    @CwtProp() usesThermalCure: boolean;
}