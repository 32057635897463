import { Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { hasSomeChanges } from '@cawita/core-front';
import { BooleanInput } from 'ng-zorro-antd/core/types';
import { InputBoolean } from 'ng-zorro-antd/core/util';
import { NzSelectComponent } from 'ng-zorro-antd/select';

@Directive({ selector: 'nz-select', standalone: true })
export class FixSelectItemHeightDirective implements OnChanges {
    @Input() @InputBoolean() tagSelect: BooleanInput = false;
    @Input() itemHeight: number = 28;
    @Input() tagHeight: number = 32;
    @Input() overflowSize: number = 10;

    constructor(private nzSelect: NzSelectComponent) { }

    ngOnChanges(changes: SimpleChanges): void {
        if(hasSomeChanges(changes, ['tagSelect', 'itemHeight', 'tagHeight', 'overflowSize'], true)) {
            this.nzSelect.nzOptionHeightPx = this.tagSelect ? this.tagHeight : this.itemHeight;
            this.nzSelect.nzOptionOverflowSize = this.overflowSize;
        }
    }
}