// thermalCure: {
//     asked: { type: String, in: thermalCures }, // The asked cure (set at the start of evaluation)
//     recommended: { type: String, in: thermalCures }, // The recommended cure based on MASAK results
//     chosen: { type: String, in: thermalCures }, // The chosen thermal cure at the end of evaluation
// },

import { isset } from "@cawita/core-front";
import { CwtApiSchema, CwtProp, CwtSchema } from "@cawita/core-front/api";

export type ThermalCure = 'energetical-sleep' | 'emotional-relaxation' | 'physical-pain' | 'bodily-appearance';

@CwtSchema()
export class EvalThermalCure extends CwtApiSchema {
    @CwtProp() asked: ThermalCure;
    @CwtProp() recommendations: ThermalCure[];
    @CwtProp() chosen: ThermalCure;

    public get default(): ThermalCure { return this.chosen ?? this.asked ?? null; }
    public get hasRecos(): boolean { return isset(this.recommendations) && this.recommendations?.length > 0; }
    public get hasChosen(): boolean { return isset(this.chosen); }
}

export const ThermalCures: ThermalCure[] = ['energetical-sleep', 'emotional-relaxation', 'physical-pain', 'bodily-appearance'];